<template>
  <div :class="$style.wrapper">
    <div :class="$style.header">
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="synchronize"
        >Синхронизировать</el-button
      >
    </div>
    <div :class="$style.form">
      <div
        v-for="(status, index) in statuses"
        :key="status.id"
        :class="$style.status"
      >
        <h3>{{ index + 1 }}.</h3>
        <div :class="$style.content">
          <div :class="$style.item">
            <div :class="$style.title">
              <h4>Название в МойСклад</h4>
            </div>
            <el-input
              readonly
              placeholder="Название в МойСклад"
              v-model="status.moySkladName"
            />
          </div>
          <div :class="$style.item">
            <div :class="$style.title">
              <h4>ID в МойСклад</h4>
            </div>
            <el-input
              readonly
              placeholder="ID в МойСклад"
              v-model="status.moySkladId"
            />
          </div>
          <div :class="$style.item">
            <div :class="$style.title">
              <h4>Название</h4>
            </div>
            <el-input placeholder="Название" v-model="status.name" />
          </div>
          <div :class="$style.item">
            <div :class="$style.title">
              <h4>Цвет статуса</h4>
            </div>
            <el-color-picker v-model="status.color" />
          </div>
        </div>
        <div :class="$style.buttons">
          <el-button
            type="danger"
            plain
            circle
            icon="el-icon-delete"
            :class="$style.remove"
            @click="remove(status.id)"
          />
          <el-button
            icon="el-icon-edit"
            plain
            circle
            :class="$style.save"
            @click="update(status)"
          ></el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notifications from '@/mixins/notifications'
import delivery from '@/delivery'
export default {
  mixins: [notifications],
  data() {
    return {
      statuses: []
    }
  },
  async created() {
    await this.getList()
  },
  methods: {
    argbToRGB(color) {
      if (!color) return

      if (color.startsWith('#', 0)) return color

      return '#' + ('000000' + (color & 0xffffff).toString(16)).slice(-6)
    },
    async synchronize() {
      const {
        error,
        value
      } = await delivery.PaymentServiceCore.OrderStatesActions.synchronize()

      if (!error) {
        this.showNotification('Синхронизация прошла успешно', 'success')

        this.statuses = value.data.map((status) => ({
          ...status,
          color: this.argbToRGB(status.color)
        }))
      }
    },
    async getList() {
      const {
        error,
        value
      } = await delivery.PaymentServiceCore.OrderStatesActions.getList()

      if (!error)
        this.statuses = value.data.map((status) => ({
          ...status,
          color: this.argbToRGB(status.color)
        }))
    },
    async remove(id) {
      const {
        error
      } = await delivery.PaymentServiceCore.OrderStatesActions.remove(id)

      if (!error) {
        this.showNotification('Статус успешно удалён', 'success')
        await this.getList()
      }
    },
    async update(status) {
      const {
        error
      } = await delivery.PaymentServiceCore.OrderStatesActions.update(
        status.id,
        { name: status.name, color: status.color }
      )

      if (!error) {
        this.showNotification('Статус успешно обновлён', 'success')
        await this.getList()
      }
    }
  }
}
</script>

<style lang="scss" module>
.wrapper {
  .header {
    @include stickyWrapper;
    .button {
      margin-right: 1rem;
    }
  }
  .form {
    padding: 1rem 3rem;
    .status {
      padding: 1.5rem 0;
      border-bottom: 0.063rem solid $extra-light-gray;
      display: flex;
      align-items: center;
      h4 {
        margin-right: 1rem;
      }
      .content {
        margin-left: 2rem;
        width: 100%;
        & > div:not(:last-child) {
          margin-bottom: 1rem;
        }
        .item {
          display: flex;
          align-items: center;
          .title {
            text-align: right;
            h4 {
              width: 15rem;
            }
          }
        }
      }
      .buttons {
        button:not(:last-child) {
          margin-bottom: 0.5rem;
        }
        margin-left: 2rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        .save {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
